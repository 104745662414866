<template>
  <v-card
    width="100%"
    flat
  >
    <v-row
      no-gutters
      :style="{ height: '12%' }"
    >
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}        
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%'}"
      class="pt-2"
    >
      <v-col>
        <v-row
          no-gutters
        >
          <v-col
            sm="7"
            md="7"
            :cols="$vssWidth < 600 ? 2 : 8"
            :class="{ 'd-flex justify-center': $vssWidth > 600}"
          >
            <v-row no-gutters>
              <v-btn
                color="primary"
                depressed
                icon
                large
                tile                         
                dark                
                @click="$refs.fileInput.click()"
              >
                <v-icon dark>
                  note_add
                </v-icon>
              </v-btn>
            </v-row>
            <v-row no-gutters>
              <v-btn
                color="primary"
                icon
                tile
                large
                dark                
                @click="clearImage"
              >
                <v-icon
                  dark                
                >
                  close
                </v-icon>
              </v-btn>
            </v-row>
            <input
              ref="fileInput"
              :key="key"
              type="file"
              style="display: none"
              accept="image/*"
              @change="handleFileChange"
            >
          </v-col>
          <v-col 
            sm="4"
            md="4"
            :cols="$vssWidth < 600 ? 9 : 8"
          >  
            <v-card 
              flat              
              max-height="200"
              max-width="150"
              class="pa-1"
              :style="{ border: '1px solid var(--v-primary-base)' }"
            >
              <v-card v-if="imageDataUrl">
                <v-img
                  :src="imageDataUrl"
                  alt="Logo da loja"
                  max-height="200"
                  max-width="150"
                />
              </v-card>              
              <v-card            
                v-else
                flat
                class="text-center d-inline-block"
              >               
                Selecione a logo  
              </v-card>
            </v-card>
          </v-col>
          <v-col 
            sm="1"
            md="1"
            :cols="$vssWidth < 600 ? 1 : 8"
          >
            <v-tooltip                
              top
            >
              <template #activator="{ attrs, on }">
                <v-icon
                  class="d-flex justify-start"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Imagem da logo é utilizada para:<br>
                - Orçamento, consignação, pedido<br>
                - Envio de notas fiscais por e-mail<br>
                <br>
                * Tamanho ideal da imagem é de 200px largura x 150px altura.
              </span>
            </v-tooltip>
          </v-col>     
        </v-row>
        <v-divider class="mb-3 mt-5" />
        <v-row no-gutters>
          <v-col
            sm="7"
            md="6"
            lg="8"
            :cols="$vssWidth < 600 ? 3 : 2"
            class="d-flex align-center"
          >
            CNPJ:
          </v-col>
          <v-col
            v-show="$vssWidth < 600"
            sm="4" 
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 2"
            class="pt-4"
          >
            <v-tooltip                
              top
            >
              <template #activator="{ attrs, on }">
                <v-icon
                  class="d-flex justify-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Caso precise realizar a edição do CNPJ de sua loja, entre em contato com nossa equipe de suporte por algum dos canais de atendimento disponíveis.
              </span>
            </v-tooltip>
          </v-col>
          <v-col
            sm="4"
            md="5"
            lg="3"
            :cols="$vssWidth < 600 ? 7 : 8"
          >
            <v-text-field
              value="24.548.550/0001-08"
              disabled
              filled
              outlined
              dense
              flat
            />
          </v-col>
          <v-col             
            sm="1"
            md="1"
            lg="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{ attrs, on }">
                <v-icon
                  v-show="$vssWidth >= 600"                  
                  v-bind="attrs"
                  :style="{ paddingBottom: $vssWidth > 600 && $vssWidth < 1264 ? '20px' : '', paddingBottom: $vssWidth > 1264 ? '20px' : '' }"
                  class="pl-6"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Caso precise realizar a edição do CNPJ de sua loja, entre em contato com nossa equipe de suporte por algum dos canais de atendimento disponíveis.
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="5"
            md="4"
            lg="8"
            :cols="$vssWidth < 600 ? 3 : 8"
            class="d-flex align-center"
          >
            Loja:
          </v-col>
          <v-col 
            sm="1"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
          >
            <v-text-field
              label=""
              value="1-9"
              disabled
              filled              
              outlined
              dense
              flat        
              single-line
            />
          </v-col>
          <v-col 
            sm="6"
            md="6"
            lg="3"
            :cols="$vssWidth < 600 ? 7 : 8"
            class="pl-2"
          >
            <v-text-field
              label=""
              value=""
              outlined
              dense
              flat        
              single-line              
              class=""
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="5"
            md="5"          
            lg="8"
            :cols="$vssWidth < 600 ? 3 : 8"
            class="d-flex align-center"
          >
            Nome fantasia:
          </v-col>
          <v-col
            sm="7"
            md="7"
            lg="4"
            :cols="$vssWidth < 600 ? 9 : 8"
            class="pt-2"
          >
            <v-text-field
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="5"
            md="4"
            lg="8"
            :cols="$vssWidth < 600 ? 3 : 8"
            class="d-flex align-center"
          >
            Matriz:
          </v-col>
          <v-col 
            sm="1"
            md="2"
            lg="1"
            :cols="$vssWidth < 600 ? 2 : 8"
            class=""
          >
            <v-text-field
              label=""
              value="1-9"
              disabled
              filled              
              outlined
              dense
              flat        
              single-line               
            />
          </v-col>
          <v-col 
            sm="6"
            md="6"
            lg="3"
            :cols="$vssWidth < 600 ? 7 : 8"
            class="pl-2"
          >
            <v-text-field
              label=""
              value=""
              outlined
              dense
              flat        
              single-line
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            lg="10"
            :cols="$vssWidth < 600 ? 3 : 8"
            class="d-flex align-center"
          >
            Inscrição municipal:
          </v-col>
          <v-col
            sm="4"
            md="4"
            lg="2"
            :cols="$vssWidth < 600 ? 9 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>        
        <v-row no-gutters>
          <v-col
            sm="8"
            md="8"
            lg="10"
            :cols="$vssWidth < 600 ? 3 : 8"
            class="d-flex align-center"
          >
            Inscrição estadual:
          </v-col>
          <v-col
            sm="4"
            md="4"
            lg="2"
            :cols="$vssWidth < 600 ? 9 : 8"
            class="pt-2"
          >
            <v-text-field
              value=""              
              outlined
              dense
              flat
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="10"
            :cols="$vssWidth < 600 ? 8 : 8"
            class="d-flex align-center"
          >
            A loja é uma franquia:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 4 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch
                inset
              />              
            </v-row>
          </v-col>
        </v-row>
        <v-row
          no-gutters   
          class="pb-0 mb-0"
        >
          <v-col
            sm="12"
            md="12"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex justify-center pb-0 mb-0"
          >
            <v-btn
              elevation="1"                                          
            >
              <v-icon
                dark
                class="pr-2"
              >
                print
              </v-icon> Impressora/Balança
            </v-btn>
          </v-col>            
        </v-row>
        <v-row
          no-gutters
        >
          <v-col
            sm="12"
            md="12"
            :cols="$vssWidth < 600 ? 12: 8"
            class="d-flex justify-center pb-7 "
          >
            <v-btn
              elevation="1"
            >
              <v-icon
                dark
                class="pr-2"
              >
                wysiwyg
              </v-icon> Planos de crediário da loja
            </v-btn>
          </v-col>            
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      imageDataUrl: null,
      key: 0,

    }
  },
  watch: {

  },
  mounted() {

  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.createImagePreview(file);
      }
    },
    createImagePreview(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageDataUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    clearImage() {
      this.imageDataUrl = null;
      this.key += 1;
    },
  },

}
</script>